import TYPES from '@/types';

import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SuspendContributionResultViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view: Vue;

  i18n_namespace = 'components.allianz-dashboard.deposits.suspend_contribution_result';

  is_loading = false;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  accept = () => {
    this.view.$emit('endProcess');
  }
}
