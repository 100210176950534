








































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';

import SuspendContributionResultViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/suspend-contribution-result-view-model';

@Component({
  name: 'SuspendContributionResult',
})
export default class SuspendContributionResult extends Vue {
  @PropSync('wasAppliedForCurrentMonth', { type: Boolean, required: true })
  synced_was_applied_for_current_month!: boolean;

  suspend_contribution_result_view_model = Vue.observable(
    new SuspendContributionResultViewModel(this),
  );
}

